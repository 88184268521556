import React, { useState } from "react"
import { Container, Row, Col, Button } from "react-bootstrap";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import SearchBanner from "../components/SearchBanner/SearchBanner";
import PropertyManagementPopup from "../components/PropertyManagementPopup/PropertyManagementPopup"
import Reviews from "../components/Reviews/Reviews"
import ReviewImg1 from "../images/reviewimg_1.png"
import ReviewImg2 from "../images/reviewimg_2.png"
import ReviewImg3 from "../images/reviewimg_3.png"

const TestPage = () => {

    // Property Management popup
    const [showPropertyManagement, setShowPropertyManagement] = useState(false);

    const handlePropertyManagementClose = () => setShowPropertyManagement(false);
    const handlePropertyManagementShow = () => setShowPropertyManagement(true);
    // Property Management popup

    const reviewsList = [
        {
            reviewImg: ReviewImg1,
            reviewName: "Ersin Kaplan",
            reviewMonth: "5 months ago",
            reviewDesc: "It is/was very nice to work with Sandfords. They have very professional staff as Ms. Phoebe Light took care of us and she was very nice and helpful at every step for hiring the flat. She even helped us for utilities applies after moving to apartment. We are very glad to hire this flat from Sandfords which we found very professional. We would reccomend Sandfords to our friends searching to rent a place in England."
        },
        {
            reviewImg: ReviewImg2,
            reviewName: "Alex Hanbury",
            reviewMonth: "3 months ago",
            reviewDesc: "Phoebe was an absolute delight to work with- we rented this flat virtually and she was great throughout the entire process. We’ve been here for 3 days and it’s already clear they have a great setup for property management requests- very professional team. Highly recommend."
        },
        {
            reviewImg: ReviewImg3,
            reviewName: "John Ralston",
            reviewMonth: "4 months ago",
            reviewDesc: "A very professional service from Sinead and the Team. They listened carefully and understood my requirements while moving the process along at pace! I would have no hesitation in using Sandfords again if I required a rental property in their Area of operations. Excellent!"
        }
    ]

    return (
        <>
            <Header />

            <SearchBanner />

            <Container className="my-5">
                <Row>
                    <Col>
                        <Button variant="primary" onClick={handlePropertyManagementShow}>Property Management Popup</Button>
                    </Col>
                </Row>
            </Container>

            <div className="mb-5">
                {
                    reviewsList.map((item) => (
                        <Reviews 
                            reviewsImg={item.reviewImg}
                            reviewsName={item.reviewName}
                            reviewsMonth={item.reviewMonth}
                            reviewsDesc={item.reviewDesc}
                        />
                    ))
                }
            </div>

            <PropertyManagementPopup showProperty={showPropertyManagement} closeProperty={handlePropertyManagementClose} />
            
            <Footer />
        </>
    )
}

export default TestPage