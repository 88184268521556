import React from 'react';
import { Form, Modal } from "react-bootstrap";
import { Link } from 'gatsby';
import './assets/styles/_index.scss';

const PropertyManagementPopup = (props) => {
    return (
        <Modal show={props.showProperty} onHide={props.closeProperty} centered className="search-overlay-modal property-popup-modal">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className="search-overlay-wrapper property-management-popup-wrapper">
                    <div className="title-text-sm text-center">Contact Sandfords</div>
                    <h6 className="search-overlay-title text-center">Share you details with us and we’ll be in touch.</h6>

                    <Form.Group className="search-overlay-form" controlId="">
                        <Form.Label>Name*</Form.Label>
                        <Form.Control type="text" placeholder="Name*" />
                    </Form.Group>

                    <Form.Group className="search-overlay-form property-modal-form" controlId="">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="text" placeholder="Phone Number" />
                    </Form.Group>

                    <Form.Group className="search-overlay-form property-modal-form" controlId="">
                        <Form.Label>Email Address*</Form.Label>
                        <Form.Control type="email" placeholder="Email Address*" />
                    </Form.Group>

                    <div className="property-modal-terms">By clicking Submit Details, you agree to our <Link to="#">Terms & Conditions</Link> and <Link to="#">Privacy Policy.</Link></div>

                    <div className="search-overlay-btn-wrapper">
                        <Link to={"#"} className="btn btn-primary-outline w-100">Submit Details</Link>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PropertyManagementPopup